import React, {useEffect, useState} from "react";
import "./Status.css";

const Status = ({serviceUrl = "", serviceName = "Service Name Not Defined", servicePort = 0}) => {


    const statusOptions = {
        OK: "OK", OFFLINE: "OFFLINE", CHECKING: "CHECKING"
    };
    const [currentStatus, setCurrentStatus] = useState(statusOptions.CHECKING);
    const [currentMeta, setCurrentMeta] = useState({});

    const retrieveStatus = () => {
        fetch(serviceUrl, {
            method: "GET", headers: {
                'Content-Type': 'application/json', // other headers if needed
            }
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then((data) => {

                if (data.status == "OK") {
                    setCurrentStatus(statusOptions.OK);

                    setCurrentMeta({
                        status: statusOptions.OK,
                        version: data.serviceVersion,
                        description: data.statusDescription
                    });

                    console.log("The retrieved data was -> ", data)
                }
            })
            .catch((error) => {
                console.error("Service Check failed. Service is unreachable.")
                setCurrentStatus(statusOptions.OFFLINE);
            });
    };

    useEffect(() => {
        retrieveStatus();
    }, []);

    return <>
        <div className="status">

            <div className="status__content">

                <div className="status__content__title">{serviceName}@{servicePort}</div>

                <div className="status__content__description-text">Description from the service: <span
                    className={currentStatus == statusOptions.OFFLINE ? "status__content__description-value status--offline":"status__content__description-value"}>{currentMeta.description ? currentMeta.description : "Not Available"}</span>
                </div>

                <div className="status__content__service-version-text">service version: <span
                    className={currentStatus == statusOptions.OFFLINE ? "status__content__service-version-value status--offline": "status__content__service-version-value"}>{currentMeta.version ? currentMeta.version : "Not Available"}</span>
                </div>

                <div className="status__content__current-status-text"><span
                    className={currentStatus == statusOptions.OFFLINE ? "status__content__current-status-value status--offline" : "status__content__current-status-value"}>{currentStatus ? currentStatus : "ERROR"}</span>
                </div>
            </div>
        </div>
    </>
};

export default Status;