import Page from "./components/page/Page";
import Header from "./components/status/Header";
import Explanation from "./components/explaination/Explanation";
import Status from "./components/status/Status";

function App() {
  return (
   <Page>
     <Header/>
       <Explanation/>

       <Status serviceUrl="https://securityservice.gehrbeck.com/status" serviceName="Security Service" servicePort={16000}/>
       <Status serviceUrl="https://mailservice.gehrbeck.com/api/public/status" serviceName="Mail Service" servicePort={15000}/>
       <Status serviceUrl="https://logging.gehrbeck.com/public/status" serviceName="Log Service" servicePort={17000}/>
   </Page>
  );
}

export default App;
