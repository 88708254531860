import React from "react";
import "./Explanation.css";

const Explanation = () => {

    return <>
        <div className="explanation">
            <div className="explanation__content">

                <h1 className="explanation__content__title">Welcome to the Status Board</h1>
                <p className="explanation__content__text">
                    The GehrBeck Status board shows meta information about the running services.
                    If you are missing a specific service it might be offline at the moment.
                    However you can check it below.
                </p>
            </div>
        </div>
    </>
};

export default Explanation;